<template>
    <div id="app">
        <van-dialog
                v-model="show"
                title="短信验证码"
                confirmButtonText="确定"
                width="64vw"
                show-cancel-button
        >
            <div class="alert_sub_title">请输入尾号 {{cutMobile}} 收到的验证码</div>
            <div class="alert_gray">50秒后可再次获取短信</div>
            <div class="alert_gray">没收到短信？<font>再次发送验证码</font></div>
            <div class="yzm">
                <van-password-input
                        :value="smsCode"
                        :mask="false"
                        :gutter="10"
                        :focused="showKeyboard"
                        @focus="showKeyboard = true"
                />
            </div>
        </van-dialog>
        <van-number-keyboard
                v-model="smsCode"
                :show="showKeyboard"
                z-index="9999"
                @blur="showKeyboard = false"
        />
    </div>
</template>

<script>
    import Vue from "vue";
    import {post} from "@/utils/http";
    import Notify from "vant/es/notify";

    export default {
        data() {
            return {
                show: true,
                smsCode: "",
                showKeyboard: true,
                info: {},
                cutMobile: ''
            };
        },
        watch: {
            smsCode(val) {
                if (val.length == 6) {
                    this.showKeyboard = false;
                }
            }
        },
        mounted() {
            this.info = Vue.ls.get('bankInfo');
            if (!this.info) {
                Notify({type: "danger", message: '信息错误'});
                return;
            }
            this.cutMobile = this.info.mobile.length > 4 ? this.info.mobile.substring(this.info.mobile.length - 4, this.info.mobile) : '-'
        },
        methods: {
            submit() {
                if (!this.info) {
                    Notify({type: "danger", message: '信息错误'});
                    return;
                }

                post("/api/guangfa/v2/create/account", {}).then((res) => {
                    Dialog.alert({message: '开通成功'}).then(() => {
                        this.$router.push({path: '/index'})
                    });
                }).catch((err) => {
                    Notify({type: "danger", message: err.message});
                });
            }
        }
    };
</script>
